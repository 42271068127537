var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Store information"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        { staticClass: "pl-8" },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.newCurrencyVisibility,
                callback: function ($$v) {
                  _vm.newCurrencyVisibility = $$v
                },
                expression: "newCurrencyVisibility",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" Please provide currency name "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Currency" },
                        model: {
                          value: _vm.tempCurrency,
                          callback: function ($$v) {
                            _vm.tempCurrency = $$v
                          },
                          expression: "tempCurrency",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.saveAndSelectCurrency(_vm.tempCurrency)
                            },
                          },
                        },
                        [_vm._v(" Save & Select ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.newCurrencyVisibility = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("text-field-row", {
            attrs: {
              title: "Exact name of store (customer facing)",
              "input-label": "Store name",
              required: "",
            },
            model: {
              value: _vm.value.name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "name", $$v)
              },
              expression: "value.name",
            },
          }),
          _c("v-divider"),
          _c(
            "field-row",
            { attrs: { title: "Country" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.countries,
                  label: "Country",
                  attach: "",
                  clearable: "",
                  dense: "",
                  required: "",
                  rules: [(v) => !!v || "Country is required!"],
                },
                model: {
                  value: _vm.value.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "country", $$v)
                  },
                  expression: "value.country",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "field-row",
            { attrs: { title: "Location" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.locationItems,
                  label: "Location",
                  attach: "",
                  clearable: "",
                  dense: "",
                  required: "",
                  rules: [(v) => !!v || "Location is required!"],
                },
                model: {
                  value: _vm.value.location,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "location", $$v)
                  },
                  expression: "value.location",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _vm.value.location === "airport"
            ? [
                _c(
                  "field-row",
                  { attrs: { title: "Airport location" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        type: "text",
                        label: "ATO code (3 letter code)",
                        counter: 3,
                        clearable: "",
                        dense: "",
                        required: "",
                        rules: [(v) => !!v || "Airport location is required!"],
                      },
                      model: {
                        value: _vm.value.airportCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "airportCode", $$v)
                        },
                        expression: "value.airportCode",
                      },
                    }),
                    _c("v-text-field", {
                      attrs: {
                        type: "text",
                        label: "Terminal/Concourse",
                        clearable: "",
                        dense: "",
                        required: "",
                        rules: [(v) => !!v || "Terminal is required!"],
                      },
                      model: {
                        value: _vm.value.airportTerminal,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "airportTerminal", $$v)
                        },
                        expression: "value.airportTerminal",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-divider"),
              ]
            : _vm.value.location === "streetSide"
            ? [
                _c("text-field-row", {
                  attrs: {
                    title: "Street side location",
                    tooltip: "Please enter the locations address",
                    required: "",
                  },
                  model: {
                    value: _vm.value.streetLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "streetLocation", $$v)
                    },
                    expression: "value.streetLocation",
                  },
                }),
                _c("v-divider"),
              ]
            : _vm._e(),
          _c("text-field-row", {
            attrs: {
              title: "Language support",
              "input-label": "Language",
              tooltip:
                "Will the site need to support any additional languages?",
              required: "",
            },
            model: {
              value: _vm.value.language,
              callback: function ($$v) {
                _vm.$set(_vm.value, "language", $$v)
              },
              expression: "value.language",
            },
          }),
          _c("v-divider"),
          _c(
            "field-row",
            { attrs: { title: "Category" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.categoryItems,
                  label: "Category",
                  attach: "",
                  clearable: "",
                  dense: "",
                  required: "",
                  rules: [(v) => !!v || "Category is required!"],
                },
                model: {
                  value: _vm.value.category,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "category", $$v)
                  },
                  expression: "value.category",
                },
              }),
              _vm.value.category === "food"
                ? _c("v-select", {
                    attrs: {
                      items: _vm.foodItems,
                      label: "Food category",
                      attach: "",
                      clearable: "",
                      dense: "",
                      required: "",
                      rules: [(v) => !!v || "Food category is required!"],
                    },
                    model: {
                      value: _vm.value.foodCategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "foodCategory", $$v)
                      },
                      expression: "value.foodCategory",
                    },
                  })
                : _vm._e(),
              _vm.value.category === "retail"
                ? _c("v-select", {
                    attrs: {
                      items: _vm.retailItems,
                      label: "Retail category",
                      attach: "",
                      clearable: "",
                      dense: "",
                      required: "",
                      rules: [(v) => !!v || "Retail category is required!"],
                    },
                    model: {
                      value: _vm.value.retailCategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "retailCategory", $$v)
                      },
                      expression: "value.retailCategory",
                    },
                  })
                : _vm._e(),
              _vm.value.category === "services"
                ? _c("v-select", {
                    attrs: {
                      items: _vm.servicesItems,
                      label: "Service category",
                      attach: "",
                      clearable: "",
                      dense: "",
                      required: "",
                      rules: [(v) => !!v || "Service category is required!"],
                    },
                    model: {
                      value: _vm.value.serviceCategory,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "serviceCategory", $$v)
                      },
                      expression: "value.serviceCategory",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "field-row",
            { attrs: { title: "Currency" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.currencyItems,
                  label: "Currency",
                  attach: "",
                  clearable: "",
                  dense: "",
                  required: "",
                  rules: [(v) => !!v || "Currency is required!"],
                },
                on: { change: _vm.onCurrencyUpdate },
                model: {
                  value: _vm.value.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "currency", $$v)
                  },
                  expression: "value.currency",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c("text-field-row", {
            attrs: {
              title: "Revenue Center ID for table numbers",
              "input-label": "ID number",
              required: "",
            },
            model: {
              value: _vm.value.revenueCenterID,
              callback: function ($$v) {
                _vm.$set(_vm.value, "revenueCenterID", $$v)
              },
              expression: "value.revenueCenterID",
            },
          }),
          _c("v-divider"),
          _c("text-field-row", {
            attrs: {
              title: "Grab API tender ID",
              "input-label": "ID number",
              required: "",
            },
            model: {
              value: _vm.value.grabApiTenderId,
              callback: function ($$v) {
                _vm.$set(_vm.value, "grabApiTenderId", $$v)
              },
              expression: "value.grabApiTenderId",
            },
          }),
          _c("v-divider"),
          _c("text-field-row", {
            attrs: {
              title: "Grab API employee ID",
              "input-label": "ID number",
              required: "",
            },
            model: {
              value: _vm.value.grabApiEmployeeId,
              callback: function ($$v) {
                _vm.$set(_vm.value, "grabApiEmployeeId", $$v)
              },
              expression: "value.grabApiEmployeeId",
            },
          }),
          _c("v-divider"),
          _c("text-field-row", {
            attrs: {
              title: "Menu Price Level (if applicable)",
              "input-label": "Menu price level",
            },
            model: {
              value: _vm.value.menuPriceLevel,
              callback: function ($$v) {
                _vm.$set(_vm.value, "menuPriceLevel", $$v)
              },
              expression: "value.menuPriceLevel",
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }