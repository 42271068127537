<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Store information</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pl-8">
            <v-dialog
                v-model="newCurrencyVisibility"
                max-width="290"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Please provide currency name
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                            label="Currency"
                            v-model="tempCurrency"
                        />
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="saveAndSelectCurrency(tempCurrency)"
                        >
                            Save & Select
                        </v-btn>

                        <v-btn
                            color="green darken-1"
                            text
                            @click="newCurrencyVisibility = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <text-field-row
                title="Exact name of store (customer facing)"
                v-model="value.name"
                input-label="Store name"
                required
            />
            <v-divider />
            <field-row
                title="Country"
            >
                <v-autocomplete
                    v-model="value.country"
                    :items="countries"
                    label="Country"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Country is required!']"
                />
            </field-row>
            <v-divider />
            <field-row
                title="Location"
            >
                <v-select
                    v-model="value.location"
                    :items="locationItems"
                    label="Location"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Location is required!']"
                />
            </field-row>
            <v-divider />
            <template v-if="value.location === 'airport'">
                <field-row title="Airport location">
                    <v-text-field
                        v-model="value.airportCode"
                        type="text"
                        label="ATO code (3 letter code)"
                        :counter="3"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'Airport location is required!']"
                    />
                    <v-text-field
                        v-model="value.airportTerminal"
                        type="text"
                        label="Terminal/Concourse"
                        clearable
                        dense
                        required
                        :rules="[v => !!v || 'Terminal is required!']"
                    />
                </field-row>
                <v-divider />
            </template>
            <template v-else-if="value.location === 'streetSide'">
                <text-field-row
                    title="Street side location"
                    tooltip="Please enter the locations address"
                    v-model="value.streetLocation"
                    required
                />
                <v-divider />
            </template>
            <text-field-row
                v-model="value.language"
                title="Language support"
                input-label="Language"
                tooltip="Will the site need to support any additional languages?"
                required
            />
            <v-divider />
            <field-row
                title="Category"
            >
                <v-select
                    v-model="value.category"
                    :items="categoryItems"
                    label="Category"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Category is required!']"
                />
                <v-select
                    v-if="value.category === 'food'"
                    v-model="value.foodCategory"
                    :items="foodItems"
                    label="Food category"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Food category is required!']"
                />
                <v-select
                    v-if="value.category === 'retail'"
                    v-model="value.retailCategory"
                    :items="retailItems"
                    label="Retail category"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Retail category is required!']"
                />
                <v-select
                    v-if="value.category === 'services'"
                    v-model="value.serviceCategory"
                    :items="servicesItems"
                    label="Service category"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Service category is required!']"
                />
            </field-row>
            <v-divider />
            <field-row
                title="Currency"
            >
                <v-select
                    v-model="value.currency"
                    :items="currencyItems"
                    label="Currency"
                    attach
                    clearable
                    dense
                    required
                    :rules="[v => !!v || 'Currency is required!']"
                    @change="onCurrencyUpdate"
                />
            </field-row>
            <v-divider />
            <text-field-row
                v-model="value.revenueCenterID"
                title="Revenue Center ID for table numbers"
                input-label="ID number"
                required
            />
            <v-divider />
            <text-field-row
                v-model="value.grabApiTenderId"
                title="Grab API tender ID"
                input-label="ID number"
                required
            />
            <v-divider />
            <text-field-row
                v-model="value.grabApiEmployeeId"
                title="Grab API employee ID"
                input-label="ID number"
                required
            />
            <v-divider />
            <text-field-row
                v-model="value.menuPriceLevel"
                title="Menu Price Level (if applicable)"
                input-label="Menu price level"
            />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
import TextFieldRow from "components/create_store/fields/TextFieldRow";

export default {
    name: "StoreInformationSection",
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    components: {
        FieldRow,
        TextFieldRow,
    },
    data() {
        return {
            newCurrencyVisibility: false,
            tempCurrency: null,
            countries: [
                {
                    text: "United States",
                    value: "US",
                },
                {
                    text: "Russia",
                    value: "RU",
                },
            ],
            locationItems: [
                {text: 'Airport', value: 'airport'},
                {text: 'Street Side', value: 'streetSide'},
            ],
            categoryItems: [
                {text: 'Food', value: 'food'},
                {text: 'Retail', value: 'retail'},
                {text: 'Services', value: 'services'},
            ],
            foodItems: [
                {text: 'American', value: 'American'},
                {text: 'Bakery', value: 'Bakery'},
                {text: 'Bar', value: 'Bar'},
                {text: 'BBQ', value: 'BBQ'},
                {text: 'British', value: 'British'},
                {text: 'Cajun', value: 'Cajun'},
                {text: 'Candy', value: 'Candy'},
                {text: 'Caribbean', value: 'Caribbean'},
                {text: 'Coffee & Drinks', value: 'coffeeAndDrinks'},
                {text: 'Cuban', value: 'Cuban'},
                {text: 'Deli', value: 'Deli'},
                {text: 'Desserts', value: 'Desserts'},
                {text: 'French', value: 'French'},
                {text: 'Global', value: 'Global'},
                {text: 'Greek', value: 'Greek'},
                {text: 'Healthy', value: 'Healthy'},
                {text: 'Indian', value: 'Indian'},
                {text: 'Italian', value: 'Italian'},
                {text: 'Kosher', value: 'Kosher'},
                {text: 'Latin', value: 'Latin'},
                {text: 'Mediterranean', value: 'Mediterranean'},
                {text: 'Mexican', value: 'Mexican'},
                {text: 'Middle Eastern', value: 'MiddleEastern'},
                {text: 'Peruvian', value: 'Peruvian'},
                {text: 'Pub', value: 'Pub'},
                {text: 'Seafood', value: 'Seafood'},
                {text: 'Smoothies', value: 'Smoothies'},
                {text: 'Snacks', value: 'Snacks'},
                {text: 'Wine Bar', value: 'WineBar'},
            ],
            retailItems: [
                {text: 'Accessories', value: 'Accessories'},
                {text: 'Art', value: 'Art'},
                {text: 'Beauty', value: 'Beauty'},
                {text: 'Books', value: 'Books'},
                {text: 'Candy', value: 'Candy'},
                {text: 'Clothing', value: 'Clothing'},
                {text: 'Duty Free', value: 'DutyFree'},
                {text: 'Electronics', value: 'Electronics'},
                {text: 'Gifts', value: 'Gifts'},
                {text: 'Luggage', value: 'Luggage'},
                {text: 'News', value: 'News'},
            ],
            servicesItems: [
                {text: 'Currency exchange', value: 'CurrencyExchange'},
                {text: 'Lounge', value: 'Lounge'},
                {text: 'Shoeshine', value: 'Shoeshine'},
                {text: 'Spa', value: 'Spa'},
            ],
            currencyItems: [
                {text: 'USD', value: 'USD'},
                {text: 'GBP', value: 'GBP'},
                {text: 'SEK', value: 'SEK'},
                {text: 'Pesos', value: 'Pesos'},
                {text: 'AUD', value: 'AUD'},
                {text: 'New', value: 'new'},
            ],
        };
    },
    methods: {
        onCurrencyUpdate(val) {
            if (val === 'new') {
                this.tempCurrency = null;
                this.newCurrencyVisibility = true;
            }
        },
        saveAndSelectCurrency(currencyName) {
            this.currencyItems.unshift({
                text: currencyName, value: currencyName,
            });
            this.value.currency = currencyName;
            this.$emit("change", this.value);
            this.newCurrencyVisibility = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../section";
</style>
